import React from 'react'

import react from '../assets/react.png'
import github from '../assets/nav-github.png'

const links = [
  { url: 'https://astroguitar.com', label: 'AWS website' },
  {
    url: 'https://woodsideweather.astroguitar.com',
    label: 'digitalocean website',
  },
  {
    url: 'https://github.com/poblabs/weewx-belchertown',
    label: 'Model and Content Assistance',
  },
  {
    url: 'https://www.taniarascia.com/',
    label: 'Design Help',
  },
]
const madeWithLinks = [
  { url: 'https://reactjs.org/', label: 'React', icon: react },
  { url: 'https://github.com/rehaupt', label: 'GitHub', icon: github },
]

export const Footer = () => {
  return (
    <footer className="footer">
      <section>
        <nav>
          <span className="desktop-only">
            Made by Ron Haupt with some good resources
          </span>
          {links.map((link) => (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              {link.label}
            </a>
          ))}
        </nav>
        <nav>
          {madeWithLinks.map((link) => (
            <a
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              <span>{link.label}</span>
              <img src={link.icon} alt={link.label} />
            </a>
          ))}
        </nav>
      </section>
    </footer>
  )
}
