import React from 'react'
import showChart from '../showChart'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

const Graphs = (props) => {
  var myChartData = props.mygraphs
  var charts = []
  charts = showChart('homepage', '', myChartData)

  console.log('returned from showChart: ', charts)
  if (!charts) return
  return (
    <>
      {/* {showChart('homepage', '', myChartData)} */}
      <div className="container">
        <div className="segment">
          <div className="weather-data">
            <div className="card chart">
              <HighchartsReact highcharts={Highcharts} options={charts[0]} />
            </div>
            <div className="card chart">
              <HighchartsReact highcharts={Highcharts} options={charts[1]} />
            </div>
            <div className="card chart">
              <HighchartsReact highcharts={Highcharts} options={charts[2]} />
            </div>
            <div className="card chart">
              <HighchartsReact highcharts={Highcharts} options={charts[3]} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Graphs
