import { useState, useEffect, useCallback } from 'react'

const UseFetch = (url) => {
  const [loading, setLoading] = useState(true)
  const [weather, setWeather] = useState([])

  const getWeather = useCallback(async () => {
    const response = await fetch(url)
    const weather = await response.json()
    setWeather(weather)
    setLoading(false)
  }, [url])

  useEffect(() => {
    getWeather()
  }, [url, getWeather])
  return { loading, weather }
}

export default UseFetch
