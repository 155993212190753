export function slugify(string) {
  return (
    string &&
    `${string}`
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  )
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export function getSimplifiedPosts(posts, options = {}) {
  return posts.map((post) => ({
    id: post.node.id,
    date: post.node.frontmatter.date,
    slug: post.node.fields.slug,
    tags: post.node.frontmatter.tags,
    categories: post.node.frontmatter.categories,
    title: options.shortTitle
      ? post.node.frontmatter.shortTitle
      : post.node.frontmatter.title,
    description: post.node.frontmatter.description,
    ...(options.thumbnails && {
      thumbnail: post.node.frontmatter?.thumbnail?.childImageSharp?.fixed,
    }),
  }))
}

export function getTaxonomyFromPosts(posts, taxonomy) {
  return posts
    .reduce((acc, post) => {
      return [...new Set([...acc, ...(post[taxonomy] || [])])]
    }, [])
    .sort()
}

// Change the color of the aqi variable according to US-EPA standards
// (adjusted to match skin colors better)
export function get_aqi_color(aqi) {
  var aqi_color = ['hsl(360, 71%, 66%)']
  if (aqi >= 301) {
    aqi_color = '#cc241d'
  } else if (aqi >= 201) {
    aqi_color = '#b16286'
  } else if (aqi >= 151) {
    aqi_color = 'rgba(255,69,69,1)'
  } else if (aqi >= 101) {
    aqi_color = 'rgba(255,127,0,1)'
  } else if (aqi >= 51) {
    aqi_color = 'rgba(255,174,0,0.9)'
  } else if (aqi < 51) {
    aqi_color = '#71bc3c'
  }
  //   // Return the color value if requested, otherwise just set the div color

  return aqi_color
}

// Handle wind arrow rotation with the ability to "rollover" past 0
// without spinning back around. e.g 350 to 3 would normally spin back around
// https://stackoverflow.com/a/19872672/1177153
export function rotateThis(newRotation) {
  if (newRotation === 'N/A') {
    return
  }
  // console.log('rotateThis: rotating to ' + newRotation)
  var currentRotation
  var finalRotation = 0
  finalRotation = finalRotation || 0 // if finalRotation undefined or 0, make 0, else finalRotation
  currentRotation = finalRotation % 360
  if (currentRotation < 0) {
    currentRotation += 360
  }
  if (currentRotation < 180 && newRotation > currentRotation + 180) {
    finalRotation -= 360
  }
  if (currentRotation >= 180 && newRotation <= currentRotation - 180) {
    finalRotation += 360
  }
  finalRotation += newRotation - currentRotation
  return finalRotation + 'deg'
  // jQuery(".wind-arrow").css("transform", "rotate(" + finalRotation + "deg)");
  // jQuery(".arrow").css("transform", "rotate(" + finalRotation + "deg)");
}
