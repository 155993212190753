import { useState, useEffect, useCallback } from 'react'

export const UseFetchForecast = (url) => {
  const [loading, setLoading] = useState(true)
  const [forecast, setForecast] = useState([])

  const getForecast = useCallback(async () => {
    const response = await fetch(url)
    const forecast = await response.json()
    setForecast(forecast)
    setLoading(false)
  }, [url])

  useEffect(() => {
    getForecast()
  }, [url, getForecast])
  return { loading, forecast }
}

export default UseFetchForecast
