import React from 'react'
import { get_aqi_color } from '../utils/helpers'

const RenderPurpleAir = (props) => {
  var myaqi = 0.0
  var textValue = ''

  var pm25 = props.val
  var location = props.location
  if (pm25 >= 0.0 && pm25 <= 12.0) {
    myaqi = Math.round(((50.0 - 0.0) / (12.0 - 0.0)) * (pm25 - 0.0) + 0.0)
    textValue = 'Good'
  } else if (pm25 >= 12.1 && pm25 <= 35.4) {
    myaqi = Math.round(((100 - 51.0) / (35.4 - 12.1)) * (pm25 - 12.1) + 51.0)
    textValue = 'Moderate'
  } else if (pm25 >= 35.5 && pm25 <= 55.4) {
    myaqi = Math.round(
      ((150.0 - 101.0) / (55.4 - 35.5)) * (pm25 - 35.5) + 101.0
    )
    textValue = 'Unhealthy for Sensitive Groups'
  } else if (pm25 >= 55.5 && pm25 <= 150.4) {
    myaqi = Math.round(
      ((200.0 - 151.0) / (150.4 - 55.5)) * (pm25 - 55.5) + 151.0
    )
    textValue = 'Unhealthy'
  } else if (pm25 >= 150.5 && pm25 <= 250.4) {
    myaqi = Math.round(
      ((300.0 - 201.0) / (250.4 - 150.5)) * (pm25 - 150.5) + 201.0
    )
    textValue = 'Very Unhealthy'
  } else if (pm25 >= 250.5 && pm25 <= 500.4) {
    myaqi = Math.round(
      ((500.0 - 301.0) / (500.4 - 250.5)) * (pm25 - 250.5) + 301.0
    )
    textValue = 'Hazardous'
  }

  var aqi_color = []

  aqi_color = get_aqi_color(myaqi)
  return (
    <div>
      <span style={{ fontSize: '1.2rem' }}>AQI: </span>
      <span className="aqi" style={{ color: aqi_color }}>
        {' '}
        {myaqi}{' '}
      </span>
      (<span style={{ color: aqi_color }}>{textValue}</span>)
      <p className="aqi-location">{location}</p>
    </div>
  )
}

export default RenderPurpleAir
