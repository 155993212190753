import React from 'react'

import { ReactComponent as MostlyClearDay } from '../assets/mostly-clear-day.svg'
import { ReactComponent as MostlyClearNight } from '../assets/mostly-clear-night.svg'
import { ReactComponent as PartlyCloudy } from '../assets/partly-cloudy-day.svg'
import { ReactComponent as PartlyCloudyNight } from '../assets/partly-cloudy-night.svg'
import { ReactComponent as MostlyCloudyNight } from '../assets/mostly-cloudy-night.svg'
import { ReactComponent as Sunny } from '../assets/clear-day.svg'
import { ReactComponent as ClearNight } from '../assets/clear-night.svg'
import { ReactComponent as Cloudy } from '../assets/cloudy-day.svg'
import { ReactComponent as MostlyCloudy } from '../assets/mostly-cloudy-day.svg'
import { ReactComponent as MostlyCloudyShowers } from '../assets/mostly-cloudy-showers.svg'
import { ReactComponent as Showers } from '../assets/showers.svg'
import { ReactComponent as BlowingDust } from '../assets/blowing-dust.svg'
import { ReactComponent as Hazy } from '../assets/hazy.svg'
import { ReactComponent as Fog } from '../assets/fog.svg'
import { ReactComponent as Rain } from '../assets/rain.svg'
import { ReactComponent as Thunderstorm } from '../assets/thunderstorm.svg'
import { ReactComponent as Snow } from '../assets/snow.svg'
// import { ReactComponent as Sleet } from '../assets/sleet.svg'

const CurrentConditions = (props) => {
  var conditionsDay = props.name
  conditionsDay = conditionsDay.replace(/\.[^/.]+$/, '')
  // console.log('CurrentConditions: ', conditionsDay)

  if (conditionsDay === 'mostly-clear-day' || conditionsDay === 'fair') {
    return <MostlyClearDay width="140px" />
  } else if (
    conditionsDay === 'partly-cloudy-day' ||
    conditionsDay === 'pcloudy'
  ) {
    return <PartlyCloudy width="160px" />
  } else if (
    conditionsDay === 'mostly-clear-night' ||
    conditionsDay === 'fairn'
  ) {
    return <MostlyClearNight width="140px" />
  } else if (conditionsDay === 'partly-cloudy-night') {
    return <PartlyCloudyNight width="140px" />
  } else if (conditionsDay === 'clear-day' || conditionsDay === 'sunny') {
    return <Sunny width="140px" />
  } else if (conditionsDay === 'clear-night') {
    return <ClearNight width="140px" />
  } else if (
    conditionsDay === 'mostly-cloudy-day' ||
    conditionsDay === 'mcloudy'
  ) {
    return <MostlyCloudy width="140px" />
  } else if (
    conditionsDay === 'mcloudyn' ||
    conditionsDay === 'mostly-cloudy-night'
  ) {
    return <MostlyCloudyNight width="140px" />
  } else if (conditionsDay === 'mostly-cloudy-showers') {
    return <MostlyCloudyShowers width="140px" />
  } else if (conditionsDay === 'showers') {
    return <Showers width="140px" />
  } else if (conditionsDay === 'blowing-dust') {
    return <BlowingDust width="140px" />
  } else if (
    conditionsDay === 'hazy' ||
    conditionsDay === 'smoke' ||
    conditionsDay === 'hazyn'
  ) {
    return <Hazy width="140px" />
  } else if (conditionsDay === 'fog') {
    return <Fog width="140px" />
  } else if (conditionsDay === 'rain') {
    return <Rain width="160px" />
  } else if (conditionsDay === 'cloudy-day' || conditionsDay === 'cloudy') {
    return <Cloudy width="140px" />
  } else if (conditionsDay === 'thunderstorm') {
    return <Thunderstorm width="160px" />
  } else if (conditionsDay === 'wintrymix') {
    return <Snow width="140px" />
  } else if (conditionsDay === 'snow') {
    return <Snow width="140px" />
  } else if (conditionsDay === 'sleet') {
    return <Snow width="140px" />
  }

  return <Thunderstorm width="60px" />
}

export default CurrentConditions
