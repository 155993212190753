import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { ExternalLinkIcon } from '../assets/ExternalLinkIcon'
import blog from '../assets/nav-blog.png'
import floppyLogo from '../assets/floppylogo.png'
import floppy from '../assets/nav-floppy.png'
import github from '../assets/nav-github.png'
import projects from '../assets/nav-projects.png'
import moon from '../assets/moon.png'
import { slugify } from '../utils/helpers'

const mainNavItems = [
  { id: 1, url: '/', icon: floppy, label: 'Home' },
  { id: 2, url: '/graphs', icon: projects, label: 'Graphs' },
  { id: 3, url: '/reports', icon: blog, label: 'Reports' },
  { id: 4, url: '/records', icon: projects, label: 'Records' },
  { id: 5, url: '/about', icon: projects, label: 'About' },
]

const socialNavItems = [
  { id: 1, url: 'https://github.com/rehaupt', icon: github, label: 'GitHub' },
]

export const Navigation = ({ theme, onUpdateTheme }) => {
  return (
    <section className="navigation">
      <div className="container">
        <nav>
          <Link to="/" className="item brand">
            <img src={floppyLogo} className="logo" alt="REH" />
            <span>Ron Haupt</span>
          </Link>

          {mainNavItems.map((item) => {
            const { id, url, icon, label } = item
            return (
              <div className="nav-item-outer" key={id}>
                {/* <img src={icon} alt={label} className="nav-image" /> */}
                <NavLink
                  to={url}
                  key={label}
                  activeclassname="active"
                  className={`item ${slugify(item.label)}`}
                >
                  <span>{label}</span>
                </NavLink>
              </div>
            )
          })}
          {socialNavItems.map((item) => {
            const { id, url, icon, label } = item
            return (
              <div className="nav-item-outer" key={id}>
                {/* <img src={icon} alt={label} className="nav-image" /> */}
                <Link
                  to={url}
                  key={label}
                  activeclassname="active"
                  className={`item ${slugify(item.label)}`}
                >
                  <span>{label}</span>
                </Link>
              </div>
            )
          })}
        </nav>
        <button className="theme-toggle" onClick={onUpdateTheme}>
          <img src={moon} alt="Theme" />{' '}
          <span>{theme === 'dark' ? 'Dark' : 'Light'}</span>
        </button>
      </div>
    </section>
  )
}
