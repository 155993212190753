export function obs_labels(label) {
  // Records Page Text and Titles
  var label_dict = {
    records_ending: "ending",
    records_days_text: "days",
    records_all_time: "All Time",
    records_temperature_records: "Woodside Temperature Records",
    records_high_temp: "Highest Temperature",
    records_low_temp: "Lowest Temperature",
    records_high_apptemp: "Highest Apparent Temperature",
    records_low_apptemp: "Lowest Apparent Temperature",
    records_high_heatindex: "Highest Heat Index",
    records_low_windchill: "Lowest Wind Chill",
    records_largest_temp_range: "Largest Daily Temperature Range",
    records_smallest_temp_range: "Smallest Daily Temperature Range",
    records_wind_records: "Wind Records",
    records_strongest_wind: "Strongest Wind Gust",
    records_daily_windrun: "Highest Daily Wind Run",
    records_rain_records: "Rain Records",
    records_highest_daily_rainfall: "Highest Daily Rainfall",
    records_highest_daily_rainrate: "Highest Daily Rain Rate",
    records_month_high_rainfall: "Month with Highest Total Rainfall",
    records_total_rainfall: "Total Rainfall for",
    records_consec_days_with_rain: "Consecutive Days With Rain",
    records_consec_days_without_rain: "Consecutive Days Without Rain",
    records_humidity_records: "Humidity Records",
    records_high_humidity: "Highest Humidity",
    records_lowest_humidity: "Lowest Humidity",
    records_highest_dewpoint: "Highest Dewpoint",
    records_lowest_dewpoint: "Lowest Dewpoint",
    records_barometer_records: "Barometer Records",
    records_high_barometer: "Highest Barometer",
    records_low_barometer: "Lowest Barometer",
    records_sun_records: "Sun Records",
    records_high_solar_rad: "Highest Solar Radiation",
    records_high_uv: "Highest UV",
    records_inTemp_records: "Inside Temp Records",
    records_high_inTemp: "Highest inside Temp",
    records_low_inTemp: "Lowest inside Temp",
  };
  return label_dict[label];
}
