import React, { useState, useEffect } from 'react'
import { obs_labels } from '../utils/ObservationLabels.js'

// import dayjs from "dayjs";
const dayjs = require('dayjs')
const Records = () => {
  const weatherData_URL =
    'https://s3-us-west-2.amazonaws.com/astroguitar.com/reh/json/weewx_data.json'
  const [weather, setWeather] = useState([])
  const getCurrent = async () => {
    const response = await fetch(weatherData_URL)
    const weather = await response.json()

    setWeather(weather)
  }

  useEffect(() => {
    getCurrent()
  }, []) /* Must make sure to run dependency array [], otherwise infinite loop ...  */

  if (weather.length === 0) {
    return null
  }
  if (weather) {
    var start_day_epoch_withrain =
      weather.alltime.rain.days_with_rainTime -
      (weather.alltime.rain.days_with_rain - 1) * 86400
    var start_day_epoch_withoutrain =
      weather.alltime.rain.days_without_rainTime -
      (weather.alltime.rain.days_without_rain - 1) * 86400
    // console.log(weather);
  } else {
    console.log('NO WEATHER DATA FROM RECORDS')
    return null
  }

  var currentDate = []
  var currentYear = []
  currentDate = weather.current.datetime
  currentYear = dayjs(currentDate).format('YYYY')
  // var forecasttime = dayjs.unix(mydate).format("dddd, MMMM D YYYY h:mm A");

  var renderDailyStats = []
  return (
    <>
      <div className="site-inner">
        <main className="content">
          <article
            className="weewx records"
            itemScope
            itemType="https://schema.org/CreativeWork"
          >
            <h4>{obs_labels('records_temperature_records')}</h4>
            <div className="row records-table">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="records-header" colSpan="5">
                      {obs_labels('records_temperature_records')}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-30"> </td>
                    <td className="records-header-year" colSpan="2">
                      {dayjs(currentDate).format('YYYY')}
                    </td>
                    <td className="records-header-alltime" colSpan="2">
                      {obs_labels('records_all_time')}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-30">{obs_labels('records_high_temp')}</td>
                    <td className="leftborder">{weather.year.outTemp.max}</td>
                    <td>
                      {dayjs(weather.year.outTemp.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.outTemp.max}
                    </td>
                    <td>
                      {dayjs(weather.alltime.outTemp.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_low_temp')}</td>
                    <td className="leftborder">{weather.year.outTemp.min}</td>
                    <td id="year-outtemp-min-ts">
                      {dayjs(weather.year.outTemp.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.outTemp.min}
                    </td>
                    <td id="at-outtemp-min-ts">
                      {dayjs(weather.alltime.outTemp.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>{obs_labels('records_high_apptemp')}</td>
                    <td className="leftborder">{weather.year.appTemp.max}</td>
                    <td id="year-apptemp-max-ts">
                      {dayjs(weather.year.appTemp.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.appTemp.max}
                    </td>
                    <td id="at-apptemp-max-ts">
                      {dayjs(weather.alltime.appTemp.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>{obs_labels('records_low_apptemp')}</td>
                    <td className="leftborder">{weather.year.appTemp.min}</td>
                    <td id="year-apptemp-min-ts">
                      {dayjs(weather.year.appTemp.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.appTemp.min}
                    </td>
                    <td id="at-apptemp-min-ts">
                      {dayjs(weather.alltime.appTemp.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>{obs_labels('records_high_heatindex')}</td>
                    <td className="leftborder">{weather.year.heatindex.max}</td>
                    <td id="year-heatindex-max-ts">
                      {dayjs(weather.year.heatindex.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.heatindex.max}
                    </td>
                    <td id="at-heatindex-max-ts">
                      {dayjs(weather.alltime.heatindex.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_low_windchill')}</td>
                    <td className="leftborder">{weather.year.windchill.min}</td>
                    <td id="year-windchill-min-ts">
                      {dayjs(weather.year.windchill.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.windchill.min}
                    </td>
                    <td id="at-windchill-min-ts">
                      {dayjs(weather.alltime.windchill.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="records-header" colSpan="5">
                      {obs_labels('records_wind_records')}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1"> </td>
                    <td className="records-header-year" colSpan="2">
                      {dayjs(currentDate).format('YYYY')}
                    </td>
                    <td className="records-header-alltime" colSpan="2">
                      {obs_labels('records_all_time')}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_strongest_wind')}</td>

                    <td className="leftborder">{weather.year.wind.max}</td>

                    <td id="year-wind-max-ts">
                      {' '}
                      {dayjs(weather.year.wind.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">{weather.alltime.wind.max}</td>
                    <td id="at-wind-max-ts">
                      {' '}
                      {dayjs(weather.alltime.wind.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>{obs_labels('records_daily_windrun')}</td>
                    <td className="leftborder">
                      {weather.year.wind.windrun_max}
                    </td>
                    <td id="year-windrun-max-ts">
                      {' '}
                      {dayjs(weather.year.wind.windrun_maxtime).format(
                        'MMM D, YYYY'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.wind.windrun_max}
                    </td>
                    <td id="at-windrun-max-ts">
                      {' '}
                      {dayjs(weather.alltime.wind.windrun_maxtime).format(
                        'MMM D, YYYY'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="records-header" colSpan="5">
                      {obs_labels('records_rain_records')}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1"> </td>
                    <td className="records-header-year" colSpan="2">
                      {dayjs(currentDate).format('YYYY')}
                    </td>
                    <td className="records-header-alltime" colSpan="2">
                      {obs_labels('records_all_time')}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_highest_daily_rainfall')}</td>
                    <td className="leftborder">
                      {weather.year.rain.rainiestday} {weather.unit_label.rain}
                    </td>
                    <td id="year-rainiest-day-ts">
                      {' '}
                      {dayjs
                        .unix(weather.year.rain.rainiestdayTime)
                        .format('MMM D, YYYY')}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.rain.rainiestday}{' '}
                      {weather.unit_label.rain}
                    </td>
                    <td id="at-rainiest-day-ts">
                      {dayjs
                        .unix(weather.alltime.rain.rainiestdayTime)
                        .format('MMM D, YYYY')}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_highest_daily_rainrate')}</td>
                    <td className="leftborder">{weather.year.rain.max}</td>
                    <td id="year-rainRate-max-ts">
                      {dayjs(weather.year.rain.maxtime).format(
                        'MMM D, YYYY HH:mm'
                      )}
                    </td>
                    <td className="leftborder">{weather.alltime.rain.max}</td>
                    <td id="at-rainRate-max-ts">
                      {' '}
                      {dayjs(weather.alltime.rain.maxtime).format(
                        'MMM D, YYYY HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_month_high_rainfall')}</td>
                    <td className="leftborder">
                      {weather.year.rain.rainiest_month}{' '}
                      {weather.unit_label.rain}
                    </td>
                    <td> {weather.year.rain.rainiest_monthTime}</td>
                    <td className="leftborder">
                      {weather.alltime.rain.rainiest_month}{' '}
                      {weather.unit_label.rain}
                    </td>
                    <td>{weather.alltime.rain.rainiest_monthTime}</td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_total_rainfall')}</td>
                    <td className="leftborder">{weather.year.rain.sum}</td>
                    <td>{dayjs(currentDate).format('YYYY')}</td>
                    <td className="leftborder">
                      {weather.alltime.rain.rain_highest_year}{' '}
                      {weather.unit_label.rain}
                    </td>
                    <td>{weather.alltime.rain.rain_highest_yearTime}</td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_consec_days_with_rain')}</td>
                    <td className="leftborder">
                      {weather.year.rain.days_with_rain}{' '}
                      {obs_labels('records_days_text')}
                    </td>
                    <td>
                      {obs_labels('records_ending')}{' '}
                      <span id="year-days-with-rainfall-ts">
                        {dayjs
                          .unix(weather.year.rain.days_with_rainTime)
                          .format('MMM D, YYYY')}
                      </span>
                    </td>
                    <td className="leftborder">
                      {weather.alltime.rain.days_with_rain}{' '}
                      {obs_labels('records_days_text')}
                    </td>
                    <td id="at-days-with-rainfall-range-ts">
                      {dayjs
                        .unix(start_day_epoch_withrain)
                        .format('MMM D, YYYY')}{' '}
                      {' - '}
                      {dayjs
                        .unix(weather.alltime.rain.days_with_rainTime)
                        .format('MMM D, YYYY')}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_consec_days_without_rain')}</td>
                    <td className="leftborder">
                      {weather.year.rain.days_without_rain}{' '}
                      {obs_labels('records_days_text')}
                    </td>
                    <td>
                      {obs_labels('records_ending')}{' '}
                      <span id="year-days-without-rainfall-ts">
                        {dayjs
                          .unix(weather.year.rain.days_without_rainTime)
                          .format('MMM D, YYYY')}
                      </span>
                    </td>
                    <td className="leftborder">
                      {weather.alltime.rain.days_without_rain}{' '}
                      {obs_labels('records_days_text')}
                    </td>
                    <td id="at-days-without-rainfall-range-ts">
                      {dayjs
                        .unix(start_day_epoch_withoutrain)
                        .format('MMM D, YYYY')}{' '}
                      {' - '}
                      {dayjs
                        .unix(weather.alltime.rain.days_without_rainTime)
                        .format('MMM D, YYYY')}
                    </td>
                  </tr>
                  <tr>
                    <td className="records-header" colSpan="5">
                      {obs_labels('records_humidity_records')}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1"> </td>
                    <td className="records-header-year" colSpan="2">
                      {dayjs(currentDate).format('YYYY')}
                    </td>
                    <td className="records-header-alltime" colSpan="2">
                      {obs_labels('records_all_time')}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_high_humidity')}</td>
                    <td className="leftborder">{weather.year.humidity.max}</td>
                    <td id="year-outHumidity-max-ts">
                      {dayjs(weather.year.humidity.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.humidity.max}
                    </td>
                    <td id="at-outHumidity-max-ts">
                      {' '}
                      {dayjs(weather.alltime.humidity.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_lowest_humidity')}</td>
                    <td className="leftborder">{weather.year.humidity.min}</td>
                    <td id="year-outHumidity-min-ts">
                      {' '}
                      {dayjs(weather.year.humidity.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.humidity.min}
                    </td>
                    <td id="at-outHumidity-min-ts">
                      {' '}
                      {dayjs(weather.alltime.humidity.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_highest_dewpoint')}</td>
                    <td className="leftborder">{weather.year.dewpoint.max}</td>
                    <td id="year-dewpoint-max-ts">
                      {' '}
                      {dayjs(weather.year.dewpoint.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.dewpoint.max}
                    </td>
                    <td id="at-dewpoint-max-ts">
                      {' '}
                      {dayjs(weather.alltime.dewpoint.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_lowest_dewpoint')}</td>
                    <td className="leftborder">{weather.year.dewpoint.min}</td>
                    <td id="year-dewpoint-min-ts">
                      {' '}
                      {dayjs(weather.year.dewpoint.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.dewpoint.min}
                    </td>
                    <td id="at-dewpoint-min-ts">
                      {' '}
                      {dayjs(weather.alltime.dewpoint.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="records-header" colSpan="5">
                      {obs_labels('records_barometer_records')}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1"> </td>
                    <td className="records-header-year" colSpan="2">
                      {dayjs(currentDate).format('YYYY')}
                    </td>
                    <td className="records-header-alltime" colSpan="2">
                      {obs_labels('records_all_time')}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_high_barometer')}</td>
                    <td className="leftborder">{weather.year.barometer.max}</td>
                    <td id="year-barometer-max-ts">
                      {' '}
                      {dayjs(weather.year.barometer.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.barometer.max}
                    </td>
                    <td id="at-barometer-max-ts">
                      {' '}
                      {dayjs(weather.alltime.barometer.maxtime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{obs_labels('records_low_barometer')}</td>
                    <td className="leftborder">{weather.year.barometer.min}</td>
                    <td id="year-barometer-min-ts">
                      {' '}
                      {dayjs(weather.year.barometer.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                    <td className="leftborder">
                      {weather.alltime.barometer.min}
                    </td>
                    <td id="at-barometer-min-ts">
                      {' '}
                      {dayjs(weather.alltime.barometer.mintime).format(
                        'MMM D, YYYY  HH:mm'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </main>
      </div>
    </>
  )
}
export default Records
