import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import favicon from '../../assets/nav-floppy.png'
import { Navigation } from '../Navigation'
import { Footer } from '../Footer'

import '../../styles/style.css'
import '../../styles/new-moon.css'

const Layout = () => {
  const [theme, setTheme] = useState('light')

  const onUpdateTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    window.localStorage.setItem('theme', newTheme)
    setTheme(newTheme)
  }

  useEffect(() => {
    const savedTheme = window.localStorage.getItem('theme')
    document.documentElement.className = theme
    if (savedTheme) {
      setTheme(savedTheme)
    }
  }, [theme])

  return (
    <>
      <link rel="shortcut icon" type="image/png" href={favicon} />
      {theme === 'dark' && (
        <link rel="stylesheet" type="text/css" href="/dark-mode.css" />
      )}
      <div id="layout" className="layout">
        <Navigation onUpdateTheme={onUpdateTheme} theme={theme} />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
