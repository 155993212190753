import React from 'react'

import { rotateThis } from '../utils/helpers'
// import { aeris_coded_weather, aeris_icon } from '../utils/AerisUtils.js'

import RenderOutTemp from './RenderOutTemp'
import CurrentConditions from './CurrentConditions'
import RenderPurpleAir from './RenderPurpleAir'
import { aeris_icon } from '../utils/AerisUtils'

import sunrise from '../assets/sunrise.png'
import sunset from '../assets/sunset.png'
import { ReactComponent as Thunderstorm } from '../assets/thunderstorm.svg'

const CurrentWeather = (props) => {
  var current = props.myweather.current
  var day = props.myweather.day
  var almanac = props.myweather.almanac
  var forecast = props.myforecast

  const dayjs = require('dayjs')

  var aqi_location = []

  var stat_6hr = 0.0
  var stat_24hr = 0.0
  var stat_30min = 0.0
  var stat_60min = 0.0
  var statavg = 0.0

  var pm25 = 0.0

  if (forecast.purpleair) {
    aqi_location = forecast.purpleair.sensor.name
    stat_6hr = forecast.purpleair.sensor.stats['pm2.5_6hour']
    stat_24hr = forecast.purpleair.sensor.stats['pm2.5_24hour']
    stat_30min = forecast.purpleair.sensor.stats['pm2.5_30minute']
    stat_60min = forecast.purpleair.sensor.stats['pm2.5_60minute']
    statavg = (stat_60min + stat_30min) / 2.0
    // console.log("statavg = " + statavg);
    if (statavg) {
      // pm25 = parseFloat(statavg).toFixed(1);
    }
    pm25 = parseFloat(statavg).toFixed(1)
    // console.log("purpleair PM2.5 value = ", pm25)
  }

  var newRotation = 0
  var newWindDir = 0
  // console.log("windir data: ", current.winddir_formatted);
  newWindDir = current.winddir_formatted
  // console.log("parsed dir: ", newWindDir);

  newRotation = rotateThis(newWindDir)

  const style = {
    transform: 'rotate(' + newRotation + ')',
    transition: 'transform 150ms ease', // smooth transition
  }

  var cloudCover
  var currentWeather

  currentWeather = forecast.metar && forecast.metar.response[0].ob.weather
  cloudCover = forecast.metar && forecast.metar.response[0].ob.sky

  // Metar
  var ceiling = 'unknown'
  if (forecast.metar.response[0].ob.ceilingFT === null) {
    ceiling = 'none reported'
  } else {
    ceiling = forecast.metar.response[0].ob.ceilingFT + ' ft'
  }
  var renderVisibility = []
  var visibility = []
  visibility = forecast.metar && forecast.metar.response[0].ob.visibilityMI
  if (!visibility) visibility = 'NA'
  renderVisibility = function () {
    return (
      <td>
        <span className="visibility">{Math.round(visibility)} miles</span>
      </td>
    )
  }

  return (
    <>
      <div className="container">
        <div className="grid">
          <header>
            <p>Last Updated: {current.datetime}</p>
          </header>
          <section>
            {/* <div className="wrapper-current"> */}
            {/* <div className="card condx"> */}
            {/* <div className="conditions"> */}
            <div className="media">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className="condx__col">
                        <CurrentConditions
                          // Aeris
                          name={aeris_icon(forecast.metar.response[0].ob.icon)}
                        />
                        <h3>{currentWeather}</h3>
                        <p>Cloud Cover: {cloudCover}%</p>
                        <p className="curwindspeed">
                          Wind: {current.windspeed} mph{'   '}
                          {current.windcompass}
                        </p>
                        <p className="curwindgust">
                          Gust: {current.windGust_formatted} mph
                        </p>
                      </td>
                      <td>
                        <RenderOutTemp
                          temp={current.outTemp_formatted}
                          appTemp={current.appTemp}
                        />

                        <table className="highlow">
                          <tbody>
                            <tr>
                              <td>High</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>{day && day.outTemp.max} &#176;F</td>
                              <td>{day && day.outTemp.min} &#176;F</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <RenderPurpleAir val={pm25} location={aqi_location} />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* </div> */}
              {/* <div className="current-data"> */}

              {/* </div> */}
              <div className="current-wind">
                <div className="compass">
                  <div className="direction">
                    <span className="curwinddir">{current.windcompass}</span>
                    <span className="curwinddeg">
                      {current.winddir_formatted}°
                    </span>
                  </div>
                  <div
                    className="arrow"
                    style={style}
                    // style={{ transform: "rotate(674deg)" }}
                  ></div>
                </div>
              </div>
              <div>
                <ul>
                  <h3>Sun & Moon</h3>
                  <li>
                    <span className="sunrise-set-image">
                      <img width={30} src={sunrise} alt="sunrise" />
                      &nbsp;
                    </span>
                    <span className="sunrise-value">
                      {almanac.sunrise_hour}:{almanac.sunrise_minute}
                    </span>
                  </li>
                  <li>
                    <span className="sunrise-set-image">
                      <img width={30} src={sunset} alt="sunset" />
                      &nbsp;
                    </span>
                    <span className="sunset-value">
                      {almanac.sunset_hour}:{almanac.sunset_minute}
                    </span>
                  </li>
                </ul>
                <ul>
                  <li className="moon-phase">{almanac.moon.moon_phase}</li>
                  <li className="moon-visible">
                    <strong>{almanac.moon.moon_fullness}%</strong> visible
                  </li>
                </ul>
              </div>
            </div>
            {/* </div> */}
          </section>
          <section>
            <div className="media">
              <div className="current-conditions">
                <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td>Barometer</td>
                      <td>
                        <span>{current.barometer} </span>
                        <span className="pressure-trend">
                          {/* TO DO ***************************/}
                          {/* <i className="fa fa-arrow-down barometer-down"></i> */}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Visibility</td>
                      {renderVisibility()}
                    </tr>
                    <tr>
                      <td>Ceiling</td>
                      <td>
                        <span>{ceiling}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Dew Point</td>
                      <td>
                        <span>{current.dewpoint} &#176;F</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Humidity</td>
                      <td>
                        <span>{current.outHumidity}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Rain</td>
                      <td>
                        <div>{current.rainDay} in</div>

                        <div>{current.rainRate}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <iframe
                  className="radar-iframe"
                  width="600px"
                  height="400px"
                  src="https://embed.windy.com/embed2.html?lat=47.415&lon=-122.810&detailLat=35.639&detailLon=-97.163&width=650&height=450&zoom=8&level=surface&overlay=radar&product=radar&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
                  title="Windy Radar"
                ></iframe>
              </div>
            </div>

            {/* </div> */}
          </section>
        </div>
      </div>
    </>
  )
}

export default CurrentWeather
