import { useState, useEffect, useCallback } from 'react'

const UseFetchNormals = (url) => {
  const [loading, setLoading] = useState(true)
  const [normals, setNormals] = useState([])

  const getNormals = useCallback(async () => {
    const response = await fetch(url)
    const normals = await response.json()
    setNormals(normals)
    setLoading(false)
  }, [url])

  useEffect(() => {
    getNormals()
  }, [url, getNormals])
  return { loading, normals }
}

export default UseFetchNormals
