import React from 'react'
import UseFetch from './UseFetch'
import UseFetchForecast from './UseFetchForecast'
import UseFetchNormals from './UseFetchNormals'
import { useChartFetch } from './useChartFetch'
import CurrentWeather from './CurrentWeather'
import CurrentForecast from './CurrentForecast'
import ForecastAeris from './ForecastAeris'
import WeatherLinks from './WeatherLinks'
import WeatherSnapshots from './WeatherSnapshots'
import Graphs from './Graphs'
import beach from '../assets/beach-sunset.png'

const urlForecast =
  'https://s3-us-west-2.amazonaws.com/astroguitar.com/reh/json/forecast.json'
const url =
  'https://s3-us-west-2.amazonaws.com/astroguitar.com/reh/json/weewx_data.json'

const urlNormals =
  'https://s3-us-west-2.amazonaws.com/astroguitar.com/reh/json/normals.json'
const urlCharts =
  'https://s3-us-west-2.amazonaws.com/astroguitar.com/reh/json/homepage.json'

// const { loading, weather } = useFetch(url);
const WeatherApp = () => {
  const { loading, weather } = UseFetch(url)
  const { myloading, forecast } = UseFetchForecast(urlForecast)
  const { normalsloading, normals } = UseFetchNormals(urlNormals)
  const { chartLoading, myChartData } = useChartFetch(urlCharts)
  if (!chartLoading) {
    console.log('chart data is loaded')
  } else return

  if (weather.length === 0) return
  // console.log(weather)
  if (!forecast || forecast.length === 0) return
  // console.log(forecast)
  if (!normals || normals.length === 0) {
    console.log('No Normals Fetched')
    return
  }
  return (
    <>
      <div className="container">
        <div style={{ marginBottom: `1.45rem`, textAlign: 'left' }}>
          {/* <img width={430} src={beach} alt="beach sunset" /> */}

          <h2>Weather for Woodside</h2>
        </div>
      </div>
      <CurrentWeather myweather={weather} myforecast={forecast} />
      {/* <CurrentForecast myforecast={forecast} mynormals={normals} /> */}
      <ForecastAeris myforecast={forecast} mynormals={normals} />
      <WeatherSnapshots myweather={weather} />
      <WeatherLinks />
      <Graphs mygraphs={myChartData} />
    </>
  )
}

export default WeatherApp
