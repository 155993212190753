import React, { useState, useEffect } from 'react'

import CurrentConditions from './CurrentConditions'

import dayjs from 'dayjs'
import { ReactComponent as RainDrop } from '../assets/wi-raindrop.svg'
import { ReactComponent as Windy } from '../assets/wi-strong-wind.svg'

import { aeris_coded_weather, aeris_icon } from '../utils/AerisUtils.js'

const CurrentForecast = (props) => {
  var forecast = props.myforecast
  if (!forecast || forecast.length === 0) return
  var forecast_24hr = props.myforecast.forecast_24hr.response[0]
  // console.log('aeris forecast: ', forecast)
  var normals = props.mynormals
  if (!normals || normals.length === 0) {
    normals = []
  }
  // var daily = props.myforecast.darksky.daily

  // console.log(
  //   'normals: ',
  //   normals.normals.response && normals.normals.response.periods[0].day
  // )
  // console.log(
  //   "normals temps: ",
  //   normals && normals.normals.response.periods[0].temp
  // )
  let conditionsDay = ' '

  let condition_text = []
  var condition_3hr_text = []
  let coded = []
  let iconVal = []
  let newIconName = []
  var new_3Hr_IconName = []

  var forecastDay = []
  var forecast3Hour = []

  // const handleThreeHour = () => {
  //   setForecastPeriod('3hr')
  // }

  // const handleDay = () => {
  //   setForecastPeriod('24hr')
  // }

  var unixtimestamp = ''
  var dayvalue = []
  var hourvalue = []
  var precip = []
  const dayjs = require('dayjs')
  var mydate = []
  mydate = forecast && forecast.timestamp
  var forecasttime = []
  if (mydate) {
    forecasttime = dayjs.unix(mydate).format('dddd, MMMM D YYYY h:mm A')
  }
  function RenderDaily(props) {
    const day = props.day
    if (!forecast_24hr.periods[day]) return
    return (
      <>
        <h5>
          {dayjs
            .unix(
              forecast_24hr.periods[day] && forecast_24hr.periods[day].timestamp
            )
            .format('ddd MM/DD')}
        </h5>
        <div className="forecast-conditions">
          <CurrentConditions
            name={aeris_icon(forecast_24hr.periods[day].icon)}
          />
          <p>
            {aeris_coded_weather(
              forecast_24hr.periods[day].weatherPrimaryCoded,
              false
            )}
          </p>
        </div>
        <div className="forecast-data">
          <span className="forecast-high">
            {forecast_24hr.periods[day].maxTempF}
            &#176;
          </span>{' '}
          |{' '}
          <span className="forecast-low">
            {forecast_24hr.periods[day].minTempF}
            &#176;
          </span>
          <div className="forecast-precip">
            <span>
              <RainDrop
                width="20px"
                viewBox="0 -7 30 30"
                filter="invert(49%) sepia(12%) saturate(2522%) hue-rotate(168deg) brightness(100%) contrast(77%)"
              />{' '}
              {forecast_24hr.periods[day].pop}
              {'%'}
            </span>
          </div>
          <span>
            <Windy
              width="20px"
              viewBox="0 -7 30 30"
              filter="invert(18%) sepia(100%) saturate(2948%) hue-rotate(239deg) brightness(83%) contrast(130%)"
            />
            {forecast_24hr.periods[day].windSpeedMPH} |{' '}
            {forecast_24hr.periods[day].windGustMPH}
          </span>
        </div>
      </>
    )
  }

  // var render3Hour = []
  // for (let i = 0; i < 9; i++) {
  //   render3Hour[i] = function () {
  //     return (
  //       <>
  //         <span id="weekday">{hourvalue[i]}</span>
  //         <br />
  //         <div className="forecast-conditions">
  //           <WeatherIconName name={new_3Hr_IconName[i]} />
  //           <br />
  //           <span className="forecast-condition-text">
  //             {condition_3hr_text[i]}
  //           </span>
  //         </div>
  //         <span>
  //           {forecast.forecast_3hr &&
  //             forecast.forecast_3hr[0].response[0].periods[i].maxTempF}
  //           &#176;
  //         </span>{' '}
  //         |{' '}
  //         <span>
  //           {forecast.forecast_3hr &&
  //             forecast.forecast_3hr[0].response[0].periods[i].minTempF}
  //           &#176;
  //         </span>
  //         <div className="forecast-precip">
  //           <i className="wi wi-raindrop wi-rotate-45 rain-precip"></i>{' '}
  //           <span>
  //             {forecast.forecast_3hr &&
  //               forecast.forecast_3hr[0].response[0].periods[i].pop}
  //             {'%'}
  //           </span>
  //         </div>
  //         <div className="forecast-wind">
  //           <i className="wi wi-strong-wind"></i>{' '}
  //           <span>
  //             {' '}
  //             {forecast.forecast_3hr &&
  //               forecast.forecast_3hr[0].response[0].periods[i].windSpeedMPH}
  //           </span>{' '}
  //           |{' '}
  //           <span>
  //             {' '}
  //             {forecast.forecast_3hr &&
  //               forecast.forecast_3hr[0].response[0].periods[i].windGustMPH}
  //             {' mph'}
  //           </span>
  //         </div>
  //       </>
  //     )
  //   }
  // }

  // const ForecastDay = () => {
  //   const [size, setSize] = useState(window.innerWidth)
  //   const checkSize = () => {
  //     setSize(window.innerWidth)
  //   }
  //   useEffect(() => {
  //     window.addEventListener('resize', checkSize)
  //     return () => {
  //       window.removeEventListener('resize', checkSize)
  //     }
  //   }, [])

  //   return (
  //     <div id="24hour-selected-forecast" style={{ display: 'block' }}>
  //       <div className="col-lg-12 row 24hr_forecasts row-no-padding">
  //         <div className="col-sm-1-5 forecast-day forecast-24hour forecast-today">
  //           {renderDay[0]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-24hour border-left">
  //           {renderDay[1]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-24hour border-left">
  //           {renderDay[2]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-24hour border-left">
  //           {renderDay[3]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-24hour border-left">
  //           {renderDay[4]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-24hour border-left">
  //           {renderDay[5]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-24hour border-left">
  //           {renderDay[6]()}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  // const ForecastThreehour = () => {
  //   const [size, setSize] = useState(window.innerWidth)
  //   const checkSize = () => {
  //     setSize(window.innerWidth)
  //   }
  //   useEffect(() => {
  //     window.addEventListener('resize', checkSize)
  //     return () => {
  //       window.removeEventListener('resize', checkSize)
  //     }
  //   }, [])

  //   return (
  //     <div id="3hour-selected-forecast" style={{ display: 'block' }}>
  //       <div className="col-lg-12 row 3hr_forecasts row-no-padding">
  //         <div className="col-sm-1-5 forecast-day forecast-3hour forecast-today">
  //           {render3Hour[0]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[1]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[2]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[3]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[4]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[5]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[6]()}
  //         </div>
  //         <div className="col-sm-1-5 forecast-day forecast-3hour border-left">
  //           {render3Hour[7]()}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // console.log(obs);
  // console.log(weatherCode[1]);

  return (
    <>
      <div className="container">
        <div className="forecast-title">
          <p>Forecast</p>
          <p className="forecast-subtitle">Last Updated on {forecasttime}</p>
          <p className="forecast-subtitle">
            Normal Temperatures for today:{' '}
            {normals.normals.response &&
              normals.normals.response.periods[0].temp.maxF.toFixed(0)}
            {'°F '}/{' '}
            {normals.normals.response &&
              normals.normals.response.periods[0].temp.minF.toFixed(0)}
            {'°F '}
          </p>
        </div>
        {/* <button className="btn" type="button" onClick={handleDay}>
          24 Hr
        </button>
        <button className="btn" type="button" onClick={handleThreeHour}>
          3 Hr
        </button> */}
        <div className="wrapper-forecast">
          <div className="anchored card">
            <RenderDaily day={0} />
          </div>
          <div className="anchored card">
            <RenderDaily day={1} />
          </div>
          <div className="anchored card">
            <RenderDaily day={2} />
          </div>
          <div className="anchored card">
            <RenderDaily day={3} />
          </div>
          <div className="anchored card">
            <RenderDaily day={4} />
          </div>
          <div className="anchored card">
            <RenderDaily day={5} />
          </div>
          <div className="anchored card">
            <RenderDaily day={6} />
          </div>
        </div>
      </div>
    </>
  )
}

export default CurrentForecast
