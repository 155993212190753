import React from 'react'

const WeatherLinks = () => {
  return (
    <>
      <div className="container">
        <section className="segment">
          <h2 className="home-heading">Links to Weather Related Sites</h2>
          <div className="weather-data">
            <div className="card">
              <ul>
                <a href="https://col.st/i7t21" rel="noopener noreferrer">
                  Colorado State RAMMB-Slider
                </a>
              </ul>
              <ul>
                <a
                  href="https://whirlwind.aos.wisc.edu/~wxp/goes17/vis_color_enh/westcoast/latest_westcoast_1.jpg"
                  rel="noopener noreferrer"
                >
                  Wisconsin-Madison GOES-17
                </a>
              </ul>
              <ul>
                <a
                  href="https://a.atmos.washington.edu/~ovens/wxloop.cgi?vis1km_pnw_color+1"
                  rel="noopener noreferrer"
                >
                  UW Northwest Satellite View
                </a>
              </ul>
              <ul>
                <a
                  href="https://www.purpleair.com/map?opt=1/mAQI/a10/cC0#11/47.4599/-122.203"
                  rel="noopener noreferrer"
                >
                  Purple Air
                </a>
              </ul>
              <ul>
                <a
                  href="https://forecast.weather.gov/MapClick.php?lon=-122.12&lat=47.45"
                  rel="noopener noreferrer"
                >
                  Local Forecast
                </a>
              </ul>
              <ul>
                <a
                  href="https://www.weather.gov/wrh/TextProduct?product=afdsew"
                  rel="noopener noreferrer"
                >
                  Forecast Discussion - Sea-Tac
                </a>
              </ul>
              <ul>
                <a
                  href="https://forecast.weather.gov/MapClick.php?lat=47.45&lon=-122.12&unit=0&lg=english&FcstType=graphical"
                  rel="noopener noreferrer"
                >
                  Hourly Forecast Graph
                </a>
              </ul>
              <ul>
                <a
                  href="https://www.cpc.ncep.noaa.gov/products/predictions/814day/"
                  rel="noopener noreferrer"
                >
                  8 to 14 Day Outlooks
                </a>
              </ul>
              <ul>
                <a
                  href="https://rapidrefresh.noaa.gov/hrrr/HRRRsmoke/"
                  rel="noopener noreferrer"
                >
                  HRRR Smoke Forecasts
                </a>
              </ul>

              <ul>
                <a
                  href="https://cliffmass.blogspot.com"
                  rel="noopener noreferrer"
                >
                  Cliff Mass Weather Blog
                </a>
              </ul>
              <ul>
                <a
                  href="https://weather.com/weather/today/l/47.45,-122.12"
                  rel="noopener noreferrer"
                >
                  Weather Channel
                </a>
              </ul>
            </div>
            <div className="radar-map link-image">
              <h3>MeteoCentre ECMWF Model Maps</h3>
              {/* <h3>MeteoCentre DWD Icon Model</h3> */}
              <div>
                <a href="https://meteocentre.com/numerical-weather-prediction/map-panel.php?mod=ecmwf&stn=PNM&mode=latest&map=na&lang=en">
                  <img
                    style={{ width: '100%' }}
                    src="https://img.meteocentre.com/numerical-weather-prediction/ecmwf_amer_00/GZ_D5_PN_036_0000.gif "
                    alt="meteocenter ecmwf"
                  />
                </a>
              </div>
            </div>
            {/* <div className="radar-map link-image">
              <h3>MeteoCentre DWD-ICON Model Maps</h3>
              <div>
                <a
                  href="https://meteocentre.com/numerical-weather-prediction/map-panel.php?mod=dwd_icon&run=18&stn=PNM&hh=000&map=na&lang=en&mode=latest&yyyy=latest&mm=latest&dd=latest"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: '100%' }}
                    src="https://img.meteocentre.com/numerical-weather-prediction/dwd_icon_amer_18/GZ_D5_PN_018_0000.gif"
                    alt="meteocenter icon"
                  />
                </a>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </>
  )
}

export default WeatherLinks
