import React from 'react'

const WeatherSnapshots = (props) => {
  var current = props.myweather.current
  var day = props.myweather.day
  var month = props.myweather.month

  const dayjs = require('dayjs')

  var mydate = []
  mydate = current && current.datetime_raw
  var currentday = []
  var currentmonth = []
  if (mydate) {
    currentday = dayjs.unix(mydate).format('dddd, MMMM D YYYY')
  }
  if (mydate) {
    currentmonth = dayjs.unix(mydate).format('MMMM YYYY')
  }

  // console.log(weather && weather.day);

  return (
    <div className="container">
      <section className="segment">
        <h2 className="home-heading">Weather Record Snapshots</h2>
        <a className="snapshot-header" href="./records">
          <h4>View all weather records here.</h4>
        </a>
        <div className="weather-data">
          <div className="card snapshot">
            <time> {currentday}</time>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>High:</b>
                  </td>
                  <td>{day && day.outTemp.max} &#176;F</td>
                  <td>
                    <b>Low:</b>
                  </td>
                  <td>
                    <span>{day && day.outTemp.min}&#176;F</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Average Wind:</b>
                  </td>
                  <td>
                    <span>{day && day.wind.average}</span>
                  </td>
                  <td>
                    <b>Highest Wind:</b>
                  </td>
                  <td>
                    <span>{day && day.wind.max}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Today's Rain:</b>
                  </td>
                  <td>
                    <span>{day && day.rain.sum}</span>
                  </td>

                  <td>
                    <b>Highest Rate:</b>
                  </td>
                  <td>
                    <span>{day && day.rain.max}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card snapshot">
            <div>
              <time>{currentmonth}</time>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>High:</b>
                  </td>
                  <td>
                    <span>{month && month.outTemp.max} &#176;F</span>
                  </td>
                  <td>
                    <b>Low:</b>
                  </td>
                  <td>
                    <span>{month && month.outTemp.min} &#176;F</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Average Wind:</b>
                  </td>
                  <td>
                    <span>{month && month.wind.average}</span>
                  </td>
                  <td>
                    <b>Highest Wind:</b>
                  </td>
                  <td>
                    <span>{month && month.wind.max}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Total Rain:</b>
                  </td>
                  <td>
                    <span>{month && month.rain.sum}</span>
                  </td>
                  <td>
                    <b>Highest Rate:</b>
                  </td>
                  <td>
                    <span>{month && month.rain.max}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WeatherSnapshots
