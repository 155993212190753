export function aeris_coded_weather(data, full_observation = false) {
  // https://www.aerisweather.com/support/docs/api/reference/weather-codes/
  //         # Aeris Weather Forecast Codes. From https://www.aerisweather.com/support/docs/api/reference/weather-codes/
  /* eslint-disable no-unused-vars */

  const forecast_cloud_code_CL = 'Clear'
  const forecast_cloud_code_FW = 'Mostly Clear'
  const forecast_cloud_code_SC = 'Partly Cloudy'
  const forecast_cloud_code_BK = 'Mostly Cloudy'
  const forecast_cloud_code_OV = 'Cloudy'

  const forecast_coverage_code_AR = 'Areas of'
  const forecast_coverage_code_BR = 'Brief'
  const forecast_coverage_code_C = 'Chance of'
  const forecast_coverage_code_D = 'Definite'
  const forecast_coverage_code_FQ = 'Frequent'
  const forecast_coverage_code_IN = 'Intermittent'
  const forecast_coverage_code_IS = 'Isolated'
  const forecast_coverage_code_L = 'Likely'
  const forecast_coverage_code_NM = 'Numerous'
  const forecast_coverage_code_O = 'Occasional'
  const forecast_coverage_code_PA = 'Patchy'
  const forecast_coverage_code_PD = 'Periods of'
  const forecast_coverage_code_S = 'Slight Chance of'
  const forecast_coverage_code_SC = 'Scattered'
  const forecast_coverage_code_VC = 'In the Vicinity'
  const forecast_coverage_code_WD = 'Widespread'

  const forecast_intensity_code_VL = 'Very Light'
  const forecast_intensity_code_L = 'Light'
  const forecast_intensity_code_H = 'Heavy'
  const forecast_intensity_code_VH = 'Very Heavy'

  const forecast_weather_code_A = 'Hail'
  const forecast_weather_code_BD = 'Blowing Dust'
  const forecast_weather_code_BN = 'Blowing Sand'
  const forecast_weather_code_BR = 'Mist'
  const forecast_weather_code_BS = 'Blowing Snow'
  const forecast_weather_code_BY = 'Blowing Spray'
  const forecast_weather_code_F = 'Fog'
  const forecast_weather_code_FR = 'Frost'
  const forecast_weather_code_H = 'Haze'
  const forecast_weather_code_IC = 'Ice Crystals'
  const forecast_weather_code_IF = 'Ice Fog'
  const forecast_weather_code_IP = 'Sleet'
  const forecast_weather_code_K = 'Smoke'
  const forecast_weather_code_L = 'Drizzle'
  const forecast_weather_code_R = 'Rain'
  const forecast_weather_code_RW = 'Rain Showers'
  const forecast_weather_code_RS = 'Rain/Snow Mix'
  const forecast_weather_code_SI = 'Snow/Sleet Mix'
  const forecast_weather_code_WM = 'Wintry Mix'
  const forecast_weather_code_S = 'Snow'
  const forecast_weather_code_SW = 'Snow Showers'
  const forecast_weather_code_T = 'Thunderstorms'
  const forecast_weather_code_UP = 'Unknown Precipitation'
  const forecast_weather_code_VA = 'Volcanic Ash'
  const forecast_weather_code_WP = 'Waterspouts'
  const forecast_weather_code_ZF = 'Freezing Fog'
  const forecast_weather_code_ZL = 'Freezing Drizzle'
  const forecast_weather_code_ZR = 'Freezing Rain'
  const forecast_weather_code_ZY = 'Freezing Spray'

  const forecast_alert_code_TOE = '911 Telephone Outage'
  const forecast_alert_code_ADR = 'Administrative Message'
  const forecast_alert_code_AQA = 'Air Quality Alert'
  const forecast_alert_code_AQ_S = 'Air Quality Alert'
  const forecast_alert_code_AS_Y = 'Air Stagnation Advisory'
  const forecast_alert_code_AR_W = 'Arctic Outflow Warning'
  const forecast_alert_code_AF_Y = 'Ashfall Advisory'
  const forecast_alert_code_MH_Y = 'Ashfall Advisory'
  const forecast_alert_code_AF_W = 'Ashfall Warning'
  const forecast_alert_code_AVW = 'Avalanche Warning'
  const forecast_alert_code_AVA = 'Avalanche Watch'
  const forecast_alert_code_BH_S = 'Beach Hazard Statement'
  const forecast_alert_code_BZ_W = 'Blizzard Warning'
  const forecast_alert_code_DU_Y = 'Blowing Dust Advisory'
  const forecast_alert_code_BS_Y = 'Blowing Snow Advisory'
  const forecast_alert_code_BW_Y = 'Brisk Wind Advisory'
  const forecast_alert_code_CAE = 'Child Abduction Emergency'
  const forecast_alert_code_CDW = 'Civil Danger Warning'
  const forecast_alert_code_CEM = 'Civil Emergency Message'
  const forecast_alert_code_CF_Y = 'Coastal Flood Advisory'
  const forecast_alert_code_CF_S = 'Coastal Flood Statement'
  const forecast_alert_code_CF_W = 'Coastal Flood Warning'
  const forecast_alert_code_CF_A = 'Coastal Flood Watch'
  const forecast_alert_code_FG_Y = 'Dense Fog Advisory'
  const forecast_alert_code_MF_Y = 'Dense Fog Advisory'
  const forecast_alert_code_FO_Y = 'Fog Advisory'
  const forecast_alert_code_SM_Y = 'Dense Smoke Advisory'
  const forecast_alert_code_MS_Y = 'Dense Smoke Advisory'
  const forecast_alert_code_DS_W = 'Dust Storm Warning'
  const forecast_alert_code_EQW = 'Earthquake Warning'
  const forecast_alert_code_EVI = 'Evacuation - Immediate'
  const forecast_alert_code_EH_W = 'Excessive Heat Warning'
  const forecast_alert_code_EH_A = 'Excessive Heat Watch'
  const forecast_alert_code_EC_W = 'Extreme Cold Warning'
  const forecast_alert_code_EC_A = 'Extreme Cold Watch'
  const forecast_alert_code_RFD = 'Extreme Fire Danger'
  const forecast_alert_code_EW_W = 'Extreme Wind Warning'
  const forecast_alert_code_FRW = 'Fire Warning'
  const forecast_alert_code_FW_A = 'Fire Weather Watch'
  const forecast_alert_code_FF_S = 'Flash Flood Statement'
  const forecast_alert_code_FF_W = 'Flash Flood Warning'
  const forecast_alert_code_FF_A = 'Flash Flood Watch'
  const forecast_alert_code_FE_W = 'Flash Freeze Warning'
  const forecast_alert_code_FL_Y = 'Flood Advisory'
  const forecast_alert_code_FL_S = 'Flood Statement'
  const forecast_alert_code_FL_W = 'Flood Warning'
  const forecast_alert_code_FA_W = 'Flood Warning'
  const forecast_alert_code_FL_A = 'Flood Watch'
  const forecast_alert_code_FA_A = 'Flood Watch'
  const forecast_alert_code_FZ_W = 'Freeze Warning'
  const forecast_alert_code_FZ_A = 'Freeze Watch'
  const forecast_alert_code_ZL_Y = 'Freezing Drizzle Advisory'
  const forecast_alert_code_ZF_Y = 'Freezing Fog Advisory'
  const forecast_alert_code_ZR_W = 'Freezing Rain Warning'
  const forecast_alert_code_UP_Y = 'Freezing Spray Advisory'
  const forecast_alert_code_FR_Y = 'Frost Advisory'
  const forecast_alert_code_GL_W = 'Gale Warning'
  const forecast_alert_code_GL_A = 'Gale Watch'
  const forecast_alert_code_HZ_W = 'Hard Freeze Warning'
  const forecast_alert_code_HZ_A = 'Hard Freeze Watch'
  const forecast_alert_code_HMW = 'Hazardous Materials Warning'
  const forecast_alert_code_SE_W = 'Hazardous Seas Warning'
  const forecast_alert_code_SE_A = 'Hazardous Seas Watch'
  const forecast_alert_code_HWO = 'Hazardous Weather Outlook'
  const forecast_alert_code_HT_Y = 'Heat Advisory'
  const forecast_alert_code_HT_W = 'Heat Warning'
  const forecast_alert_code_UP_W = 'Heavy Freezing Spray Warning'
  const forecast_alert_code_UP_A = 'Heavy Freezing Spray Watch'
  const forecast_alert_code_SU_Y = 'High Surf Advisory'
  const forecast_alert_code_SU_W = 'High Surf Warning'
  const forecast_alert_code_HW_W = 'High Wind Warning'
  const forecast_alert_code_HW_A = 'High Wind Watch'
  const forecast_alert_code_HF_W = 'Hurricane Force Wind Warning'
  const forecast_alert_code_HF_A = 'Hurricane Force Wind Watch'
  const forecast_alert_code_HU_S = 'Hurricane Local Statement'
  const forecast_alert_code_HU_W = 'Hurricane Warning'
  const forecast_alert_code_HU_A = 'Hurricane Watch'
  const forecast_alert_code_FA_Y = 'Hydrologic Advisory'
  const forecast_alert_code_IS_W = 'Ice Storm Warning'
  const forecast_alert_code_LE_W = 'Lake Effect Snow Warning'
  const forecast_alert_code_LW_Y = 'Lake Wind Advisory'
  const forecast_alert_code_LS_Y = 'Lakeshore Flood Advisory'
  const forecast_alert_code_LS_S = 'Lakeshore Flood Statement'
  const forecast_alert_code_LS_W = 'Lakeshore Flood Warning'
  const forecast_alert_code_LS_A = 'Lakeshore Flood Watch'
  const forecast_alert_code_LEW = 'Law Enforcement Warning'
  const forecast_alert_code_LAE = 'Local Area Emergency'
  const forecast_alert_code_LO_Y = 'Low Water Advisory'
  const forecast_alert_code_MA_S = 'Marine Weather Statement'
  const forecast_alert_code_NUW = 'Nuclear Power Plant Warning'
  const forecast_alert_code_RHW = 'Radiological Hazard Warning'
  const forecast_alert_code_RA_W = 'Rainfall Warning'
  const forecast_alert_code_FW_W = 'Red Flag Warning'
  const forecast_alert_code_RFW = 'Red Flag Warning'
  const forecast_alert_code_RP_S = 'Rip Current Statement'
  const forecast_alert_code_SV_W = 'Severe Thunderstorm Warning'
  const forecast_alert_code_SV_A = 'Severe Thunderstorm Watch'
  const forecast_alert_code_SV_S = 'Severe Weather Statement'
  const forecast_alert_code_TO_S = 'Severe Weather Statement'
  const forecast_alert_code_SPW = 'Shelter In Place Warning'
  const forecast_alert_code_NOW = 'Short Term Forecast'
  const forecast_alert_code_SC_Y = 'Small Craft Advisory'
  const forecast_alert_code_SW_Y = 'Small Craft Advisory For Hazadous Seas'
  const forecast_alert_code_RB_Y = 'Small Craft Advisory for Rough Bar'
  const forecast_alert_code_SI_Y = 'Small Craft Advisory for Winds'
  const forecast_alert_code_SO_W = 'Smog Warning'
  const forecast_alert_code_SQ_W = 'Snow Squall Warning'
  const forecast_alert_code_SQ_A = 'Snow Squall Watch'
  const forecast_alert_code_SB_Y = 'Snow and Blowing Snow Advisory'
  const forecast_alert_code_SN_W = 'Snowfall Warning'
  const forecast_alert_code_MA_W = 'Special Marine Warning'
  const forecast_alert_code_SPS = 'Special Weather Statement'
  const forecast_alert_code_SG_W = 'Storm Surge Warning'
  const forecast_alert_code_SS_W = 'Storm Surge Warning'
  const forecast_alert_code_SS_A = 'Storm Surge Watch'
  const forecast_alert_code_SR_W = 'Storm Warning'
  const forecast_alert_code_SR_A = 'Storm Watch'
  const forecast_alert_code_TO_W = 'Tornado Warning'
  const forecast_alert_code_TO_A = 'Tornado Watch'
  const forecast_alert_code_TC_S = 'Tropical Cyclone Statement'
  const forecast_alert_code_TR_S = 'Tropical Storm Local Statement'
  const forecast_alert_code_TR_W = 'Tropical Storm Warning'
  const forecast_alert_code_TR_A = 'Tropical Storm Watch'
  const forecast_alert_code_TS_Y = 'Tsunami Advisory'
  const forecast_alert_code_TS_W = 'Tsunami Warning'
  const forecast_alert_code_TS_A = 'Tsunami Watch'
  const forecast_alert_code_TY_S = 'Typhoon Local Statement'
  const forecast_alert_code_TY_W = 'Typhoon Warning'
  const forecast_alert_code_TY_A = 'Typhoon Watch'
  const forecast_alert_code_VOW = 'Volcano Warning'
  const forecast_alert_code_WX_Y = 'Weather Advisory'
  const forecast_alert_code_WX_W = 'Weather Warning'
  const forecast_alert_code_WI_Y = 'Wind Advisory'
  const forecast_alert_code_WC_Y = 'Wind Chill Advisory'
  const forecast_alert_code_WC_W = 'Wind Chill Warning'
  const forecast_alert_code_WC_A = 'Wind Chill Watch'
  const forecast_alert_code_WI_W = 'Wind Warning'
  const forecast_alert_code_WS_W = 'Winter Storm Warning'
  const forecast_alert_code_WS_A = 'Winter Storm Watch'
  const forecast_alert_code_LE_A = 'Winter Storm Watch'
  const forecast_alert_code_BZ_A = 'Winter Storm Watch'
  const forecast_alert_code_WW_Y = 'Winter Weather Advisory'
  const forecast_alert_code_LE_Y = 'Winter Weather Advisory'
  const forecast_alert_code_ZR_Y = 'Winter Weather Advisory'

  //      # Aeris European Alert Codes. From https://www.aerisweather.com/support/docs/aeris-maps/reference/alert-types/
  const forecast_alert_code_AW_WI_MN = 'Minor Wind'
  const forecast_alert_code_AW_WI_MD = 'Moderate Wind'
  const forecast_alert_code_AW_WI_SV = 'Servere Wind'
  const forecast_alert_code_AW_WI_EX = 'Extreme Wind'
  const forecast_alert_code_AW_SI_MN = 'Minor Snow/Ice'
  const forecast_alert_code_AW_SI_MD = 'Moderate Snow/Ice'
  const forecast_alert_code_AW_SI_SV = 'Servere Snow/Ice'
  const forecast_alert_code_AW_SI_EX = 'Extreme Snow/Ice'
  const forecast_alert_code_AW_TS_MN = 'Minor Thunderstorm'
  const forecast_alert_code_AW_TS_MD = 'Moderate Thunderstorm'
  const forecast_alert_code_AW_TS_SV = 'Servere Thunderstorm'
  const forecast_alert_code_AW_TS_EX = 'Extreme Thunderstorm'
  const forecast_alert_code_AW_LI_MN = 'Minor Lightning'
  const forecast_alert_code_AW_LI_MD = 'Moderate Lightning'
  const forecast_alert_code_AW_LI_SV = 'Servere Lightning'
  const forecast_alert_code_AW_LI_EX = 'Extreme Lightning'
  const forecast_alert_code_AW_FG_MN = 'Minor Fog'
  const forecast_alert_code_AW_FG_MD = 'Moderate Fog'
  const forecast_alert_code_AW_FG_SV = 'Servere Fog'
  const forecast_alert_code_AW_FG_EX = 'Extreme Fog'
  const forecast_alert_code_AW_HT_MN = 'Minor High Temperature'
  const forecast_alert_code_AW_HT_MD = 'Moderate High Temperature'
  const forecast_alert_code_AW_HT_SV = 'Servere High Temperature'
  const forecast_alert_code_AW_HT_EX = 'Extreme High Temperature'
  const forecast_alert_code_AW_LT_MN = 'Minor Low Temperature'
  const forecast_alert_code_AW_LT_MD = 'Moderate Low Temperature'
  const forecast_alert_code_AW_LT_SV = 'Servere Low Temperature'
  const forecast_alert_code_AW_LT_EX = 'Extreme Low Temperature'
  const forecast_alert_code_AW_CE_MN = 'Minor Coastal Event'
  const forecast_alert_code_AW_CE_MD = 'Moderate Coastal Event'
  const forecast_alert_code_AW_CE_SV = 'Servere Coastal Event'
  const forecast_alert_code_AW_CE_EX = 'Extreme Coastal Event'
  const forecast_alert_code_AW_FR_MN = 'Minor Forest Fire'
  const forecast_alert_code_AW_FR_MD = 'Moderate Forest Fire'
  const forecast_alert_code_AW_FR_SV = 'Servere Forest Fire'
  const forecast_alert_code_AW_FR_EX = 'Extreme Forest Fire'
  const forecast_alert_code_AW_AV_MN = 'Minor Avalanche'
  const forecast_alert_code_AW_AV_MD = 'Moderate Avalanche'
  const forecast_alert_code_AW_AV_SV = 'Servere Avalanche'
  const forecast_alert_code_AW_AV_EX = 'Extreme Avalanche'
  const forecast_alert_code_AW_RA_MN = 'Minor Rainfall'
  const forecast_alert_code_AW_RA_MD = 'Moderate Rainfall'
  const forecast_alert_code_AW_RA_SV = 'Servere Rainfall'
  const forecast_alert_code_AW_RA_EX = 'Extreme Rainfall'
  const forecast_alert_code_AW_FL_MN = 'Minor Flooding'
  const forecast_alert_code_AW_FL_MD = 'Moderate Flooding'
  const forecast_alert_code_AW_FL_SV = 'Servere Flooding'
  const forecast_alert_code_AW_FL_EX = 'Extreme Flooding'
  const forecast_alert_code_AW_RF_MN = 'Minor Rain Flooding'
  const forecast_alert_code_AW_RF_MD = 'Moderate Rain Flooding'
  const forecast_alert_code_AW_RF_SV = 'Servere Rain Flooding'
  const forecast_alert_code_AW_RF_EX = 'Extreme Rain Flooding'
  const forecast_alert_code_AW_UK_MN = 'Minor Unknown'
  const forecast_alert_code_AW_UK_MD = 'Moderate Unknown'
  const forecast_alert_code_AW_UK_SV = 'Servere Unknown'
  const forecast_alert_code_AW_UK_EX = 'Extreme Unknown'

  var output = ''
  var coverage_code = data.split(':')[0]
  var intensity_code = data.split(':')[1]
  var weather_code = data.split(':')[2]

  var cloud_dict = {
    CL: forecast_cloud_code_CL,
    FW: forecast_cloud_code_FW,
    SC: forecast_cloud_code_SC,
    BK: forecast_cloud_code_BK,
    OV: forecast_cloud_code_OV,
  }

  var coverage_dict = {
    AR: forecast_coverage_code_AR,
    BR: forecast_coverage_code_BR,
    C: forecast_coverage_code_C,
    D: forecast_coverage_code_D,
    FQ: forecast_coverage_code_FQ,
    IN: forecast_coverage_code_IN,
    IS: forecast_coverage_code_IS,
    L: forecast_coverage_code_L,
    NM: forecast_coverage_code_NM,
    O: forecast_coverage_code_O,
    PA: forecast_coverage_code_PA,
    PD: forecast_coverage_code_PD,
    S: forecast_coverage_code_S,
    SC: forecast_coverage_code_SC,
    VC: forecast_coverage_code_VC,
    WD: forecast_coverage_code_WD,
  }

  var intensity_dict = {
    VL: forecast_intensity_code_VL,
    L: forecast_intensity_code_L,
    H: forecast_intensity_code_H,
    VH: forecast_intensity_code_VH,
  }

  var weather_dict = {
    A: forecast_weather_code_A,
    BD: forecast_weather_code_BD,
    BN: forecast_weather_code_BN,
    BR: forecast_weather_code_BR,
    BS: forecast_weather_code_BS,
    BY: forecast_weather_code_BY,
    F: forecast_weather_code_F,
    FR: forecast_weather_code_FR,
    H: forecast_weather_code_H,
    IC: forecast_weather_code_IC,
    IF: forecast_weather_code_IF,
    IP: forecast_weather_code_IP,
    K: forecast_weather_code_K,
    L: forecast_weather_code_L,
    R: forecast_weather_code_R,
    RW: forecast_weather_code_RW,
    RS: forecast_weather_code_RS,
    SI: forecast_weather_code_SI,
    WM: forecast_weather_code_WM,
    S: forecast_weather_code_S,
    SW: forecast_weather_code_SW,
    T: forecast_weather_code_T,
    UP: forecast_weather_code_UP,
    VA: forecast_weather_code_VA,
    WP: forecast_weather_code_WP,
    ZF: forecast_weather_code_ZF,
    ZL: forecast_weather_code_ZL,
    ZR: forecast_weather_code_ZR,
    ZY: forecast_weather_code_ZY,
  }
  /* eslint-enable no-unused-vars */
  // Check if the weather_code is in the cloud_dict and use that if it's there. If not then it's a combined weather code.
  if (cloud_dict.hasOwnProperty(weather_code)) {
    return cloud_dict[weather_code]
  } else {
    // Add the coverage if it's present, and full observation forecast is requested
    if (coverage_code && full_observation) {
      output += coverage_dict[coverage_code] + ' '
    }
    // Add the intensity if it's present
    if (intensity_code) {
      output += intensity_dict[intensity_code] + ' '
    }
    // Weather output
    output += weather_dict[weather_code]
  }

  return output
}

export function aeris_icon(data) {
  // https://www.aerisweather.com/support/docs/api/reference/icon-list/
  var icon_name = data.split('.')[0] // Remove .png
  // console.log("aeris_icon: " + icon_name);
  var icon_dict = {
    blizzard: 'snow',
    blizzardn: 'snow',
    blowingsnow: 'snow',
    blowingsnown: 'snow',
    clear: 'clear-day',
    clearn: 'clear-night',
    cloudy: 'cloudy',
    cloudyn: 'cloudy',
    cloudyw: 'cloudy',
    cloudywn: 'cloudy',
    cold: 'clear-day',
    coldn: 'clear-night',
    drizzle: 'rain',
    drizzlen: 'rain',
    dust: 'hazy',
    dustn: 'hazy',
    fair: 'mostly-clear-day',
    fairn: 'mostly-clear-night',
    drizzlef: 'rain',
    fdrizzlen: 'rain',
    flurries: 'sleet',
    flurriesn: 'sleet',
    flurriesw: 'sleet',
    flurrieswn: 'sleet',
    fog: 'fog',
    fogn: 'fog',
    freezingrain: 'rain',
    freezingrainn: 'rain',
    hazy: 'hazy',
    hazyn: 'hazyn',
    hot: 'clear-day',
    'N/A ': 'unknown',
    mcloudy: 'mostly-cloudy-day',
    mcloudyn: 'mostly-cloudy-night',
    mcloudyr: 'mostly-cloudy-showers',
    mcloudyrn: 'mostly-cloudy-showers',
    mcloudyrw: 'rain',
    mcloudyrwn: 'rain',
    mcloudys: 'snow',
    mcloudysn: 'snow',
    mcloudysf: 'snow',
    mcloudysfn: 'snow',
    mcloudysfw: 'snow',
    mcloudysfwn: 'snow',
    mcloudysw: 'mostly-cloudy-day',
    mcloudyswn: 'mostly-cloudy-night',
    mcloudyt: 'thunderstorm',
    mcloudytn: 'thunderstorm',
    mcloudytw: 'thunderstorm',
    mcloudytwn: 'thunderstorm',
    mcloudyw: 'mostly-cloudy-day',
    mcloudywn: 'mostly-cloudy-night',
    na: 'unknown',
    pcloudy: 'partly-cloudy-day',
    pcloudyn: 'partly-cloudy-night',
    pcloudyr: 'mostly-cloudy-showers',
    pcloudyrn: 'rain',
    pcloudyrw: 'rain',
    pcloudyrwn: 'rain',
    pcloudys: 'snow',
    pcloudysn: 'snow',
    pcloudysf: 'snow',
    pcloudysfn: 'snow',
    pcloudysfw: 'snow',
    pcloudysfwn: 'snow',
    pcloudysw: 'partly-cloudy-day',
    pcloudyswn: 'partly-cloudy-night',
    pcloudyt: 'thunderstorm',
    pcloudytn: 'thunderstorm',
    pcloudytw: 'thunderstorm',
    pcloudytwn: 'thunderstorm',
    pcloudyw: 'partly-cloudy-day',
    pcloudywn: 'partly-cloudy-night',
    rain: 'rain',
    rainn: 'rain',
    rainandsnow: 'rain',
    rainandsnown: 'rain',
    raintosnow: 'rain',
    raintosnown: 'rain',
    rainw: 'rain',
    showers: 'showers',
    showersn: 'showers',
    showersw: 'showers',
    showerswn: 'showers',
    sleet: 'sleet',
    sleetn: 'sleet',
    sleetsnow: 'sleet',
    sleetsnown: 'sleet',
    smoke: 'hazy',
    smoken: 'hazy',
    snow: 'snow',
    snown: 'snow',
    snoww: 'snow',
    snowwn: 'snow',
    snowshowers: 'snow',
    snowshowersn: 'snow',
    snowshowersw: 'snow',
    snowshowerswn: 'snow',
    snowtorain: 'snow',
    snowtorainn: 'snow',
    sunny: 'clear-day',
    sunnyn: 'clear-night',
    sunnyw: 'mostly-clear-day',
    sunnywn: 'mostly-clear-night',
    tstorm: 'thunderstorm',
    tstormn: 'thunderstorm',
    tstorms: 'thunderstorm',
    tstormsn: 'thunderstorm',
    tstormsw: 'thunderstorm',
    tstormswn: 'thunderstorm',
    wind: 'wind',
    wintrymix: 'sleet',
    wintrymixn: 'sleet',
  }

  return icon_dict[icon_name]
}

// Change the color of the aqi variable according to US-EPA standards
// (adjusted to match skin colors better)
export function get_aqi_color(aqi) {
  var aqi_color = ['hsl(360, 71%, 66%)']
  if (aqi >= 301) {
    aqi_color = '#cc241d'
  } else if (aqi >= 201) {
    aqi_color = '#b16286'
  } else if (aqi >= 151) {
    aqi_color = 'rgba(255,69,69,1)'
  } else if (aqi >= 101) {
    aqi_color = 'rgba(255,127,0,1)'
  } else if (aqi >= 51) {
    aqi_color = 'rgba(255,174,0,0.9)'
  } else if (aqi < 51) {
    aqi_color = '#71bc3c'
  }
  //   // Return the color value if requested, otherwise just set the div color

  return aqi_color
}
