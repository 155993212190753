import { useState, useEffect, useCallback } from 'react';

export const useChartFetch = (url) => {
  const [chartLoading, setLoading] = useState(true);
  const [myChartData, setWeather] = useState([]);

  const getChartData = useCallback(async () => {
    const response = await fetch(url);
    const myChartData = await response.json();
    setWeather(myChartData);
    setLoading(false);
  }, [url]);

  useEffect(() => {
    getChartData();
  }, [url, getChartData]);
  return { chartLoading, myChartData };
};
