import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import About from './components/About'
import WeatherApp from './components/WeatherApp'
import Records from './components/Records'
import NoPage from './components/NoPage'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<WeatherApp />} />
            <Route path="about" element={<About />} />
            <Route path="records" element={<Records />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
