import React from 'react'

const RenderOutTemp = (props) => {
  var outTemp_color = ['#eeeeee']
  var outTemp = props.temp
  var appTemp = props.appTemp

  if (outTemp <= 0) {
    outTemp_color = '#1278c8'
  } else if (outTemp <= 25) {
    outTemp_color = '#30bfef'
  } else if (outTemp <= 32) {
    outTemp_color = '#1fafdd'
  } else if (outTemp <= 40) {
    outTemp_color = 'rgba(0,172,223,1)'
  } else if (outTemp <= 50) {
    outTemp_color = '#71bc3c'
  } else if (outTemp <= 55) {
    outTemp_color = 'rgba(90,179,41,0.8)'
  } else if (outTemp <= 65) {
    outTemp_color = 'rgba(131,173,45,1)'
  } else if (outTemp <= 70) {
    outTemp_color = 'rgba(206,184,98,1)'
  } else if (outTemp <= 75) {
    outTemp_color = 'rgba(255,174,0,0.9)'
  } else if (outTemp <= 80) {
    outTemp_color = 'rgba(255,153,0,0.9)'
  } else if (outTemp <= 85) {
    outTemp_color = 'rgba(255,127,0,1)'
  } else if (outTemp <= 90) {
    outTemp_color = 'rgba(255,79,0,0.9)'
  } else if (outTemp <= 95) {
    outTemp_color = 'rgba(255,69,69,1)'
  } else if (outTemp <= 110) {
    outTemp_color = 'rgba(255,104,104,1)'
  } else if (outTemp >= 111) {
    outTemp_color = 'rgba(218,113,113,1)'
  }

  return (
    <div className="current-temp">
      <span style={{ color: outTemp_color, fontSize: 45, paddingBottom: 10 }}>
        {outTemp}
        <sup className="outtempunitlabelsuper"> °F</sup>
      </span>
      <p>Feels like: {Math.round(appTemp)} &#176;F</p>
    </div>
  )
}

export default RenderOutTemp
